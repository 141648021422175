import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["image", "fname"]
  static values = { index: Number }

  connect() {
    this.imageTargets.forEach((element, index) => {
      if (element.src.search(this.fnameTarget.value) > 0) {
        element.hidden = false
        this.indexValue = index
      }
      else {
        element.hidden = true
      }
    })
    this.showCurrentSlide()
  }

  initialize() {
  }

  next(event) {
    event.preventDefault()
    if (this.indexValue === (this.imageTargets.length - 1)) {
      this.indexValue = 0
    } else {
      this.indexValue++
    }
    this.showCurrentSlide()
  }

  previous(event) {
    event.preventDefault()
    if (this.indexValue == 0) {
      this.indexValue = this.imageTargets.length - 1
    }
    else {
      this.indexValue--
    }
    this.showCurrentSlide()
  }


  showCurrentSlide() {
    this.imageTargets.forEach((element, index) => {
      if (index == this.indexValue) {
        element.hidden = false
        this.fnameTarget.value = element.src
      }
      else {
        element.hidden = true
      }
    })
  }
}

import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    super.connect()
  }

  edit_identifier(event) {
    event.preventDefault()
    const identifier = prompt("Bitte neues Kürzel eingeben", "");
    this.stimulate("Coursemembership#change_identifier", event.target, identifier)
  }

  reset_password(event) {
    event.preventDefault()
    if (confirm("Passwort wirklich zurücksetzen?")) {
      this.stimulate("Coursemembership#reset_password", event.target)
    }
  }

  subtract_duties(event) {
    event.preventDefault()
    this.stimulate("Coursemembership#subtract_duties", event.target)
  }

  subtract_attitude(event) {
    event.preventDefault()
    this.stimulate("Coursemembership#subtract_attitude", event.target)
  }

  add(event) {
    event.preventDefault()
    this.stimulate("Coursemembership#add", event.target)
  }

  resurrect(event) {
    event.preventDefault()
    if (confirm("Wirklich heilen?")) {
      this.stimulate("Coursemembership#resurrect", event.target)
    }
  }

  remove(event) {
    event.preventDefault()
    if (confirm("Wirklich löschen?")) {
      this.stimulate("Coursemembership#remove", event.target)
    }
  }

  allow_hero_modification(event) {
    event.preventDefault()
    this.stimulate("Coursemembership#allow_hero_modification", event.target)
  }

  show_history(event) {
    event.preventDefault()
    this.stimulate("Coursemembership#show_history", event.target)
  }
}

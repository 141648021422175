import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    document.addEventListener('notify', this.notify)
  }

  disconnect() {
    document.removeEventListener('notify', this.notify)
  }

  notify = event => {
    alert(event.detail.message)
  }
}

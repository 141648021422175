import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    super.connect()
  }

  change_join_code(event) {
    event.preventDefault()
    if (confirm("Zutrittscode ändern?")) {
      this.stimulate("Teaching#change_join_code", event.target)
    }
  }

  allow_hero_modifications(event) {
    event.preventDefault()
    if (confirm("Alle Helden zur einmaligen Bearbeitung freigeben?")) {
      this.stimulate("Teaching#allow_hero_modifications", event.target)
    }
  }

  change_course_name(event) {
    event.preventDefault()
    const new_name = prompt("Bitte neuen Namen für die Klasse eingeben", "");
    this.stimulate("Teaching#change_course_name", event.target, new_name)
  }

  copy_student_identifiers(event) {
    event.preventDefault()
    if (confirm("Kürzel vom Klassenlehrer übernehmen?")) {
      this.stimulate("Teaching#copy_student_identifiers", event.target)
    }
  }

}


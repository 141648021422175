import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    document.addEventListener('showmodal', this.showmodal)
  }

  disconnect() {
    document.removeEventListener('showmodal', this.showmodal)
  }

  showmodal = event => {
    const modal = document.getElementById("modal")
    modal.innerHTML = event.detail.content
    modal.classList.remove("hidden")
  }

  hidemodal = event => {
    const modal = document.getElementById("modal")
    modal.classList.add("hidden")
    modal.innerHTML = ""
  }
}
